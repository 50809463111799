import React, { useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';

import { Box, Heading } from '@rover/kibble/core';
import SearchResultCard from '@rover/react-lib/src/components/cards/SearchResultCard';
import EXPERIMENTS from '@rover/react-lib/src/constants/experiments.constants';
import { useA11yContext } from '@rover/react-lib/src/context/A11yContext';
import type { OptimizedSearchResult } from '@rover/rsdk/src/apiClient/latest';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';
import { useCurrentUser } from '@rover/rsdk/src/modules/Data/Core/CurrentUser';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { isMobileBrowser } from '@rover/rsdk/src/modules/Network/userAgent';
import { ServiceType } from '@rover/shared/js/constants/service';
import useExperimentsShim from '@rover/shared/js/hooks/useExperimentsShim';
import { SearchPageDaycareNoResults } from '@rover/shared/js/search/utilities/analyticsEvents';
import { getReferrer, referrerIsRover } from '@rover/shared/js/utils/referrer';
import { type SearchFilters, type SearchResult, Callout } from '@rover/types';
import emitMetric from '@rover/utilities/emitMetric';
import fireDataLayerEvent from '@rover/utilities/fireDataLayerEvent';
import { getQueryParams } from '@rover/utilities/url';

import BestMatchExplanationModal from '../Modals/BestMatchExplanationModal';
import { HeaderScenariosEnum, searchHeaderBuilder } from '../SearchSectionHeader';
import SelectedProvider from '../SelectedProvider';

import AlternateSearchResults from './AlternateSearchResults';
import { EmptyResults, SearchError } from './Errors';
import NoFacilitiesCallout from './NoFacilitiesCallout';
import { SearchSittersBanner } from './SearchSittersBanner';

type Props = {
  alternateSearchResults: SearchResult[];
  alternateServiceName: string;
  alternateServiceDescription: string;
  bestMatchSearchResults?: SearchResult[];
  bestMatchConfigs?: string[];
  currentFilters: SearchFilters;
  didFiltersChangeSincePageLoad: boolean;
  isBestMatchesSearch?: boolean;
  isBestMatchExperience?: boolean;
  hasBestMatches?: boolean;
  hoveringResultIndex?: number | null;
  isAuthenticated?: boolean | null;
  isMapExpanded: boolean;
  language: string;
  memberProfileLinkParams: Record<string, string | undefined>;
  searchErrorMessage: string;
  searchResults: SearchResult[];
  selectedProviderResult?: SearchResult;
  searchQueryDates: Record<'startDate' | 'endDate', Date | undefined>;
  searchQueryDistinctDates: { dates?: string };
  searchResponseNumberOfResults: number;
  isSeoPage: boolean;
  onFavoriteToggled: (personOpk: string) => void;
  onResultCardMouseEnter?: (index: number) => void;
  onResultCardMouseLeave?: () => void;
  searchCardRefs?: (instance: HTMLDivElement, index: number) => void;
  preferCatReviews: boolean;
  rolloutPriceTransparency?: boolean;
  onClick?: (entityOpk: string, isFacility: boolean) => void;
  searchCallouts?: Callout[];
};

const SearchResults: React.FC<Props> = ({
  alternateSearchResults,
  alternateServiceName,
  alternateServiceDescription,
  bestMatchConfigs = [],
  bestMatchSearchResults = [],
  currentFilters,
  didFiltersChangeSincePageLoad,
  isBestMatchesSearch = false, // Whether or not the search query is filtering for best matches.
  isBestMatchExperience = false, // Whether or not the best matches headers should render.
  hasBestMatches = false, // Whether or not there are best matches to display. This helps with some logic relating to headers on subsequent pages.
  memberProfileLinkParams,
  searchErrorMessage,
  searchResults,
  selectedProviderResult,
  searchQueryDates,
  searchQueryDistinctDates,
  isAuthenticated,
  isMapExpanded, // This controls the size of SearchResultsCard for viewports < 992px
  language,
  onFavoriteToggled,
  searchResponseNumberOfResults,
  onResultCardMouseEnter,
  onResultCardMouseLeave,
  hoveringResultIndex,
  searchCardRefs,
  isSeoPage,
  preferCatReviews,
  rolloutPriceTransparency,
  onClick = () => {},
  searchCallouts,
}) => {
  const { i18n } = useI18n();
  const { setScreenReaderAnnouncement } = useA11yContext();
  const [isBestMatchExplanationModalOpen, setIsBestMatchExplanationModalOpen] = useState(false);
  const { isLoading: isUserDataLoading, user } = useCurrentUser();
  const source = isMobileBrowser() ? 'mobile_web' : 'web';
  const personOpk = user?.opk ?? '';

  const searchErrorOccurred = searchErrorMessage !== '';
  const noSearchResults = !searchResults || searchResults.length === 0;
  const {
    serviceType,
    atDaycareFacility: facilitiesIncludedFilter,
    dogCount,
    catCount,
    puppyCount,
    spacesRequired,
    page,
    pet,
  } = currentFilters;

  /**
   * Daycare facility related state
   */
  const experimentsShim = useExperimentsShim();
  const { isVariant } = experimentsShim || { isVariant: () => false };
  const [noFacilitiesCalloutDismissed, setNoFacilitiesCalloutDismissed] = useState(false);
  const [fireNoFacilitiesEvent, setFireNoFacilitiesEvent] = useState(true);
  const hasDaycareFacilityInResults = searchResults.some((result) => result.isFacility);
  const userInDaycareFacilityExperiment = isVariant(
    EXPERIMENTS.GINGR_DAYCARE_FILTER_OUTSIDE_MARKETS_EXPERIMENT
  );
  const userInBoardingFacilityExperiment = isVariant(
    EXPERIMENTS.GINGR_BOARDING_FILTER_OUTSIDE_MARKETS_EXPERIMENT
  );

  const currentFilterKeys = Object.keys(currentFilters);
  const initialQueryParams = useMemo(() => getQueryParams(), []);

  const referrerIsUsHomepageSearch =
    referrerIsRover() &&
    ['/'].includes(getReferrer()?.pathname ?? '') &&
    Object.keys(initialQueryParams).some((key) => currentFilterKeys.includes(key)); // ensure initial query params are actual search filters

  const allTypes = currentFilters.atDaycareFacility && currentFilters.inSittersHome;

  // Don't show callout if "All Types" filter is selected and user did not search from the homepage
  const shouldHideForAllTypesFilter = allTypes && !referrerIsUsHomepageSearch;

  const displayNoFacilitiesCallout = [
    (userInDaycareFacilityExperiment && serviceType === ServiceType.DOGGY_DAY_CARE) ||
      (userInBoardingFacilityExperiment && serviceType === ServiceType.OVERNIGHT_BOARDING),
    facilitiesIncludedFilter,
    !hasDaycareFacilityInResults,
    !noSearchResults,
    !noFacilitiesCalloutDismissed,
    !shouldHideForAllTypesFilter,
  ].every(Boolean);

  if (displayNoFacilitiesCallout && fireNoFacilitiesEvent) {
    setFireNoFacilitiesEvent(false);
    emitAnalyticsEvent(new SearchPageDaycareNoResults());
  }
  // END Daycare facility related state

  const handleOpenBestMatchExplanationModal = (): void => {
    setIsBestMatchExplanationModalOpen(true);
    fireDataLayerEvent({
      event: 'lead-time-best-matches-info-click',
      personId: personOpk,
    });
  };

  /**
   * `onClick` is wrapped so we can build in data without having to prop-drill
   * into `SearchResultCard`s.
   */
  const wrappedOnClick = (
    entityOpk: string,
    isFacility: boolean,
    isBestMatch?: boolean,
    maybeBestMatch?: boolean,
    isPriceTransparency?: boolean
  ): void => {
    // Previous logic inside `SelectedProvider` called this only if `isPriceTransparency` was false.
    if (!isPriceTransparency) {
      onClick(entityOpk, isFacility);
    }

    if (isBestMatch && bestMatchConfigs.includes('short_lead_time')) {
      fireDataLayerEvent({
        event: 'lead-time-search-result-best-match-view',
        personId: personOpk,
        providerId: entityOpk,
      });
    }

    if (isBestMatch || maybeBestMatch) {
      const bestMatchStatus = isBestMatch ? 'isBestMatch' : 'maybeBestMatch';
      emitMetric('search_best_match_clicked', { bestMatchStatus, source });
    }
  };

  useEffect(() => {
    if (!isUserDataLoading && bestMatchSearchResults.length > 0) {
      if (bestMatchConfigs.length > 0) {
        // Emit metrics when we have best match results, with each applicable config tagged.
        bestMatchConfigs.forEach((config) => {
          emitMetric('search_best_match_results_rendered', { config, source });
        });
      }

      if (bestMatchConfigs.includes('short_lead_time')) {
        fireDataLayerEvent({
          event: 'lead-time-search-result-best-match-qty',
          personId: personOpk,
          numberOfProviders: bestMatchSearchResults.length,
        });
      }
    }
  }, [bestMatchSearchResults.length, bestMatchConfigs, isUserDataLoading, personOpk, source]);

  if (searchErrorOccurred) {
    return <SearchError />;
  }

  if (didFiltersChangeSincePageLoad) {
    setScreenReaderAnnouncement(
      i18n._(
        t`Search results have changed due to your input.  There are now ${searchResponseNumberOfResults} results.`
      )
    );
  }

  // If we have a full page of best matches, we don't want to show the empty results component.
  const emptyResultsComponent =
    noSearchResults && bestMatchSearchResults.length ? null : (
      <EmptyResults searchCallouts={searchCallouts} />
    );

  const headers = searchHeaderBuilder({
    i18n,
    dates: searchQueryDates,
    hasBestMatches,
    hasResults: !noSearchResults,
    isBestMatchesSearch,
    isBestMatchExperience,
    isInitialPage: page === 1,
    isPriceTransparency: rolloutPriceTransparency,
    language,
    petData: { dogCount, catCount, puppyCount, petCount: pet.length },
    selectedProvider: selectedProviderResult as OptimizedSearchResult,
    serviceType,
    spacesRequired,
    isSeoPage,
  });

  const selectedProviderHeader = headers[HeaderScenariosEnum.SELECTED_SITTER];
  const bestMatchHeader = headers[HeaderScenariosEnum.BEST_MATCHES];
  const mainResultsHeader = headers[HeaderScenariosEnum.SEARCH_RESULTS];

  return (
    <>
      <Heading as="h2" a11yHidden>
        <Trans>Search Results</Trans>
      </Heading>

      {selectedProviderResult && (
        <>
          <SearchSittersBanner
            icon={selectedProviderHeader.icon}
            title={i18n._(selectedProviderHeader.title)}
            text={
              selectedProviderHeader.primarySubheader &&
              i18n._(selectedProviderHeader.primarySubheader)
            }
            secondText={
              selectedProviderHeader.secondarySubheader &&
              i18n._(selectedProviderHeader.secondarySubheader)
            }
            banner={selectedProviderHeader.alertText && i18n._(selectedProviderHeader.alertText)}
            alertType={selectedProviderHeader.alertSeverity}
          />
          <SelectedProvider
            startDate={searchQueryDates.startDate}
            endDate={searchQueryDates.endDate}
            dates={searchQueryDistinctDates.dates}
            displayExpanded={!isMapExpanded}
            isActive={false}
            isAuthenticated={isAuthenticated}
            language={language}
            memberProfileLinkParams={memberProfileLinkParams}
            onFavoriteToggled={onFavoriteToggled}
            searchResult={selectedProviderResult}
            serviceType={serviceType}
            // @ts-expect-error
            ref={searchCardRefs}
            isSeoPage={isSeoPage}
            preferCatReviews={preferCatReviews}
            isPriceTransparency={rolloutPriceTransparency}
          />
        </>
      )}

      {bestMatchHeader && (
        <SearchSittersBanner
          icon={bestMatchHeader.icon}
          title={i18n._(bestMatchHeader.title)}
          text={bestMatchHeader.primarySubheader && i18n._(bestMatchHeader.primarySubheader)}
          secondText={
            bestMatchHeader.secondarySubheader && i18n._(bestMatchHeader.secondarySubheader)
          }
          banner={bestMatchHeader.alertText && i18n._(bestMatchHeader.alertText)}
          alertType={bestMatchHeader.alertSeverity}
          infoIconOnClick={handleOpenBestMatchExplanationModal}
        />
      )}

      {bestMatchSearchResults.length > 0 &&
        bestMatchSearchResults.map((searchResult: SearchResult, index: number) => [
          <SearchResultCard
            displayExpanded={!isMapExpanded}
            index={index}
            isActive={index === hoveringResultIndex}
            isAuthenticated={isAuthenticated}
            key={searchResult.personOpk}
            language={language}
            memberProfileLinkParams={memberProfileLinkParams}
            onBlur={onResultCardMouseLeave}
            onFavoriteToggled={onFavoriteToggled}
            onFocus={onResultCardMouseEnter}
            onMouseEnter={onResultCardMouseEnter}
            onMouseLeave={onResultCardMouseLeave}
            searchResult={searchResult}
            // @ts-expect-error
            ref={searchCardRefs}
            isSeoPage={isSeoPage}
            preferCatReviews={preferCatReviews}
            onClick={wrappedOnClick}
            isPriceTransparency={rolloutPriceTransparency}
            itemProp="provider"
          />,
        ])}

      {mainResultsHeader && (
        <SearchSittersBanner
          icon={mainResultsHeader.icon}
          title={i18n._(mainResultsHeader.title)}
          text={mainResultsHeader.primarySubheader && i18n._(mainResultsHeader.primarySubheader)}
          secondText={
            mainResultsHeader.secondarySubheader && i18n._(mainResultsHeader.secondarySubheader)
          }
          banner={mainResultsHeader.alertText && i18n._(mainResultsHeader.alertText)}
          alertType={mainResultsHeader.alertSeverity}
          infoIconOnClick={
            mainResultsHeader.isBestMatchHeader ? handleOpenBestMatchExplanationModal : undefined
          }
        />
      )}

      {displayNoFacilitiesCallout && (
        <Box p="6x" background="secondary">
          <NoFacilitiesCallout
            serviceType={serviceType}
            dismiss={() => setNoFacilitiesCalloutDismissed(true)}
          />
        </Box>
      )}

      {noSearchResults
        ? emptyResultsComponent
        : searchResults.map((searchResult: SearchResult, index: number) => [
            <SearchResultCard
              displayExpanded={!isMapExpanded}
              index={index + bestMatchSearchResults.length}
              isActive={index + bestMatchSearchResults.length === hoveringResultIndex}
              isAuthenticated={isAuthenticated}
              key={searchResult.personOpk}
              language={language}
              memberProfileLinkParams={memberProfileLinkParams}
              onBlur={onResultCardMouseLeave}
              onFavoriteToggled={onFavoriteToggled}
              onFocus={onResultCardMouseEnter}
              onMouseEnter={onResultCardMouseEnter}
              onMouseLeave={onResultCardMouseLeave}
              searchResult={searchResult}
              // @ts-expect-error
              ref={searchCardRefs}
              isSeoPage={isSeoPage}
              preferCatReviews={preferCatReviews}
              onClick={wrappedOnClick}
              isPriceTransparency={rolloutPriceTransparency}
              itemProp="provider"
            />,
          ])}

      {alternateSearchResults.length > 0 && (
        <AlternateSearchResults
          displayExpanded={!isMapExpanded}
          isAuthenticated={isAuthenticated}
          language={language}
          memberProfileLinkParams={memberProfileLinkParams}
          onFavoriteToggled={onFavoriteToggled}
          searchResults={alternateSearchResults}
          serviceName={alternateServiceName}
          serviceDescription={alternateServiceDescription}
          isSeoPage={isSeoPage}
          preferCatReviews={preferCatReviews}
          isPriceTransparency={rolloutPriceTransparency}
        />
      )}

      <BestMatchExplanationModal
        isOpen={isBestMatchExplanationModalOpen}
        onClose={() => setIsBestMatchExplanationModalOpen(false)}
      />
    </>
  );
};

export default SearchResults;
