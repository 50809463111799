import { MessageDescriptor } from '@lingui/core';

import { AlertSeverity } from '@rover/react-lib/src/components/notifications/Alert/Alert';
import {
  AvailableForSelectedDatesEnum,
  OptimizedSearchResult,
} from '@rover/rsdk/src/apiClient/latest';
import { ServiceType } from '@rover/shared/js/constants/service';

import {
  AlertContentEnum,
  getAlertContent,
  getSubHeader,
  HEADER_TITLES,
  HeaderIconEnum,
  HeaderTitlesEnum,
  SubHeadersEnum,
} from './constants';
import { DateData, PrebuiltHeaderProps } from './types';

type Props = {
  dateData: DateData;
  hasBestMatches?: boolean;
  hasPets: boolean;
  individualPetTypeCountDescription: string;
  isBestMatchExperience?: boolean;
  isBestMatchesSearch?: boolean;
  isInitialPage?: boolean;
  isPriceTransparency?: boolean;
  selectedProvider?: OptimizedSearchResult;
  serviceType: ServiceType;
  isSeoPage?: boolean;
};

const buildMainSearchResultsHeader = ({
  dateData,
  hasBestMatches,
  hasPets = true,
  individualPetTypeCountDescription,
  // This is based on if is_best_match_experience in the derived data is true, which will show them different headers
  isBestMatchExperience,
  // This is based on whether the if the search was filtered for best matches regardless of results
  isBestMatchesSearch,
  isInitialPage,
  isPriceTransparency,
  isSeoPage = false,
  selectedProvider,
  serviceType,
}: Props): PrebuiltHeaderProps | object | null => {
  const { startDate, endDate } = dateData;
  const availability =
    selectedProvider?.availableForSelectedDates || AvailableForSelectedDatesEnum.unknown;
  const hasDates = !!startDate && !!endDate;
  const hasSelectedProvider = !!selectedProvider;
  const isSelectedProviderAvailable = availability === AvailableForSelectedDatesEnum.available;

  let finalIsBestMatchesSearch = isBestMatchesSearch;
  let finalHasBestMatchResults = hasBestMatches;

  // Ensure logical constraints
  if (!isBestMatchExperience) {
    // If it is not a best matches experience, then there cannot be a best
    // matches search or results.
    finalIsBestMatchesSearch = false;
    finalHasBestMatchResults = false;
  } else if (!finalIsBestMatchesSearch) {
    // If it is a best matches experience but there is no best matches search,
    // there cannot be best matches results.
    finalHasBestMatchResults = false;
  }

  if (isSeoPage) return null;

  if (
    !hasSelectedProvider &&
    !isPriceTransparency &&
    !isBestMatchExperience &&
    ((!hasDates && !hasPets) || (hasDates && hasPets))
  ) {
    return null;
  }

  let icon;
  let title: PrebuiltHeaderProps['title'] | undefined;
  let primarySubheader: PrebuiltHeaderProps['primarySubheader'] | undefined;
  let alertText: PrebuiltHeaderProps['alertText'] | undefined;
  let isBestMatchHeader = false;

  // For selected provider scenarios
  const handleSelectedProvider = (): void => {
    icon = HeaderIconEnum.MAP;

    if (isBestMatchExperience) {
      if (!hasDates) {
        icon = HeaderIconEnum.SEARCH;
        title = HEADER_TITLES[HeaderTitlesEnum.FIND_MATCH];

        if (isPriceTransparency) {
          alertText = getAlertContent(AlertContentEnum.PRICE_TRANSPARENCY_ADD_DATES, {
            serviceType,
            individualPetTypeCountDescription,
          }) as MessageDescriptor;
        } else {
          primarySubheader = getSubHeader(SubHeadersEnum.ADD_DATES, {
            serviceType,
          }) as MessageDescriptor;
        }

        return;
      }

      if (finalIsBestMatchesSearch && !finalHasBestMatchResults) {
        // If it's a best matches search, then this header title will be based on
        // the absence of best match results.
        title = HEADER_TITLES[HeaderTitlesEnum.SITTERS_IN_AREA];
      } else if (!finalHasBestMatchResults) {
        // This header renders if there are no best match results, regardless of
        // whether it's a best matches search.
        icon = HeaderIconEnum.BOLT;
        title = HEADER_TITLES[HeaderTitlesEnum.BEST_MATCHES];
        isBestMatchHeader = true;
      }

      if (isPriceTransparency) {
        alertText = getAlertContent(AlertContentEnum.PRICE_TRANSPARENCY_PRICES_FOR_UNIT, {
          dateData,
          serviceType,
          individualPetTypeCountDescription,
        }) as MessageDescriptor;
      } else {
        primarySubheader = getSubHeader(SubHeadersEnum.SEEING_PROVIDERS_DATES, {
          serviceType,
          dateData,
        }) as MessageDescriptor;
      }

      return;
    }

    if (isPriceTransparency) {
      title = HEADER_TITLES[HeaderTitlesEnum.OTHER_SITTERS_IN_AREA];

      if (hasDates) {
        primarySubheader = getSubHeader(SubHeadersEnum.SHOWING_OTHER_PROVIDERS, {
          serviceType,
        }) as MessageDescriptor;
      }

      return;
    }

    if (hasDates && !isSelectedProviderAvailable) {
      title = HEADER_TITLES[HeaderTitlesEnum.AVAILABLE_SITTERS_NEAR_YOU];
      primarySubheader = getSubHeader(SubHeadersEnum.POST_SELECTED_SITTER_RESULTS, {
        serviceType,
        dateData,
      }) as MessageDescriptor;

      return;
    }

    title = HEADER_TITLES[HeaderTitlesEnum.SITTERS_CLOSE_TO_YOU];

    if (hasDates) {
      primarySubheader = getSubHeader(SubHeadersEnum.POST_SELECTED_SITTER_RESULTS, {
        serviceType,
        dateData,
      }) as MessageDescriptor;
    } else {
      primarySubheader = getSubHeader(SubHeadersEnum.ADD_DATES, {
        serviceType,
      }) as MessageDescriptor;
    }
  };

  // For non-selected provider scenarios where dates are not selected
  const handleNoDates = (): void => {
    if (!isPriceTransparency && !hasPets) {
      icon = HeaderIconEnum.MAP;
      title = HEADER_TITLES[HeaderTitlesEnum.SITTERS_IN_AREA];
      return;
    }

    icon = HeaderIconEnum.SEARCH;
    title = HEADER_TITLES[HeaderTitlesEnum.FIND_MATCH];

    if (isPriceTransparency) {
      alertText = getAlertContent(AlertContentEnum.PRICE_TRANSPARENCY_ADD_DATES, {
        serviceType,
        individualPetTypeCountDescription,
      }) as MessageDescriptor;
    } else {
      primarySubheader = getSubHeader(SubHeadersEnum.ADD_DATES, {
        serviceType,
      }) as MessageDescriptor;
    }
  };

  // For the rest of the search results, regardless of best matches
  const handleStandardHeaders = (): void => {
    icon = HeaderIconEnum.MAP;

    if (finalHasBestMatchResults && !isInitialPage) {
      title = HEADER_TITLES[HeaderTitlesEnum.OTHER_SITTERS_IN_AREA];
      primarySubheader = getSubHeader(SubHeadersEnum.SHOWING_OTHER_PROVIDERS, {
        serviceType,
      }) as MessageDescriptor;

      return;
    }

    if (finalHasBestMatchResults) {
      icon = HeaderIconEnum.BOLT;
      title = HEADER_TITLES[HeaderTitlesEnum.BEST_MATCHES];
      isBestMatchHeader = true;
    } else {
      title = HEADER_TITLES[HeaderTitlesEnum.SITTERS_IN_AREA];
    }

    if (isPriceTransparency) {
      alertText = getAlertContent(AlertContentEnum.PRICE_TRANSPARENCY_PRICES_FOR_UNIT, {
        dateData,
        serviceType,
        individualPetTypeCountDescription,
      }) as MessageDescriptor;
    } else {
      primarySubheader = getSubHeader(SubHeadersEnum.SEEING_PROVIDERS_DATES, {
        serviceType,
        dateData,
      }) as MessageDescriptor;
    }
  };

  // Main decision logic
  if (hasSelectedProvider) {
    handleSelectedProvider();
  } else if (!hasDates) {
    handleNoDates();
  }

  if (!title) {
    handleStandardHeaders();
  }

  return {
    icon,
    title,
    primarySubheader,
    alertText,
    alertSeverity: startDate && endDate ? AlertSeverity.INFO : AlertSeverity.WARNING,
    isBestMatchHeader,
  };
};

export default buildMainSearchResultsHeader;
